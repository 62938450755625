<template>
  <div>
    <!-- MODAL PREVIEW -->
    <b-modal
      id="previewModals"
      ref="previewModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      size="xl"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Preview Attachment
        </h3>
      </div>
      <div class="d-flex justify-content-center">
        <img
          v-if="previewType == 'image'"
          :src="previewUrl"
          class="img-fluid"
        >
        <object
          v-if="previewType == 'pdf'"
          :data="previewUrl"
          type="application/pdf"
          width="100%"
          height="600px"
        >
          <p>Your web browser doesn't have a PDF plugin. <a :href="previewUrl">click here to download the PDF file.</a>
          </p>
        </object>
      </div>

      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="$bvModal.hide('previewModals')"
        ><i class="fa fa-undo" /> Back</b-button>
        &nbsp;
      </div>
    </b-modal>
    <li
      v-bind="$attrs"
      class="timeline-item"
      :class="[`timeline-variant-${variant}`, fillBorder ? `timeline-item-fill-border-${variant}` : null]"
      v-on="$listeners"
    >
      <div
        v-if="!icon"
        class="timeline-item-point"
      />
      <div
        v-else
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
      >
        <feather-icon :icon="icon" />
      </div>

      <slot>
        <b-card class="bg-light-secondary">
          <b-card-title>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h5 v-text="user" />
              <small
                v-b-tooltip.hover
                class="timeline-item-time text-nowrap"
                :title="time"
              >
                {{ moment(time).fromNow() }}
              </small>
            </div>
          </b-card-title>
          <b-card-text v-html="description" />
          <a
            v-if="attachment"
            class="text-primary"
            @click="getAttachment(attachment)"
          >
            <feather-icon
              :icon="'PaperclipIcon'"
              size="14"
            />
          </a>
        </b-card>
      </slot>
    </li>
  </div>
</template>

<script>
import _accessApi from '@/router/_accessApi'
import moment from 'moment'

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    user: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    attachment: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      previewUrl: null,
      previewType: null,
    }
  },
  methods: {
    moment,
    getAttachment(path) {
      if (path === null) return
      // if path is image

      this.previewUrl = `${_accessApi.localhost.upload_path_preview}${path}`
      if (path.toLowerCase().match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/) != null) {
        this.previewType = 'image'
        this.$refs.previewModal.show()
      } else if (path.toLowerCase().match(/\.(pdf)$/) != null) {
        this.previewType = 'pdf'
        this.$refs.previewModal.show()
      } else {
        window.open(this.previewUrl, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name,
$color in $colors {

  @each $color_type,
  $color_value in $color {
    @if $color_type=='base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
